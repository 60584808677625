// Digunakan oleh view (component) yang diset keep-alive: false di mainapplayout


export default {
  computed: {
    isLoaded() {
      return this.objectMdl.isLoaded();
    }
  },
  created() {
    this.fetchData();
  },
  activated() {
    // diperlukan untuk kasus token expired, kemudian pindah
    // ke halaman login, dan karena mainapplayout-nya di-keep-alive
    // maka halaman tersebut masih aktif di-background, tanpa data.
    // setelah login kembali ke halaman tersebut dan perlu fetch ulang datanya.
    // contoh kasus: anggotadetail
    // CEK APAKAH MASIH DIPERLUKAN??? KARENA APP.VUE SUDAH TIDAK KEEP ALIVE.
    this.fetchData();
  }
}
