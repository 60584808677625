<template>
  <div class="card">
    <header class="card-header has-background-primary">
      <div @click.stop="$router.go(-1)" class="card-header-icon">
        <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
      </div>
      <p class="card-header-title has-text-light">AKTIVITAS {{ komponenTitle }}</p>
      <router-link
        v-if="canChangeKomponen"
        :to="changeRouterTo"
        class="card-header-icon has-text-light"
      >
        <b-icon class="has-text-light" size="is-small" icon="edit" />
      </router-link>
      <a class="card-header-icon" v-if="canDeleteKomponen" @click.stop.prevent="confirmDelete">
        <b-icon icon="trash" size="is-small" class="has-text-light" />
      </a>
    </header>
    <div class="card-content events-card">
      <div class="columns is-mobile">
        <div class="column detail is-12">
          <span class="is-pulled-right">
            <small>{{ aktKomponen.aktivitas.tanggal }}</small>
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column detail has-text-weight-semibold is-4">Departemen</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div
          class="column detail has-text-left"
        >{{ aktKomponen.aktivitas.sub_departemen.nama }} [{{aktKomponen.aktivitas.jenis_keterampilan }}]</div>
      </div>
      <div class="columns is-mobile">
        <div class="column detail has-text-weight-semibold is-4">Preceptor</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div class="column detail has-text-left">{{ aktKomponen.aktivitas.preceptor }}</div>
      </div>
      <div class="columns is-mobile">
        <div class="column detail has-text-weight-semibold is-4">Mahasiswa</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div class="column detail has-text-left">{{ aktKomponen.aktivitas.pspd }}</div>
      </div>
      <div v-if="aktKomponen.aktivitas.pelayanan" class="columns is-mobile">
        <div class="column detail has-text-weight-semibold is-4">Pasien</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div class="column detail has-text-left">{{ aktKomponen.aktivitas.pelayanan }}</div>
      </div>
      <div class="columns is-mobile">
        <div class="column detail has-text-weight-semibold is-4">Lokasi</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div class="column detail has-text-left">
          <template v-if="aktKomponen.lokasi">{{ aktKomponen.lokasi.nama }}</template>
          <template v-else>-</template>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column detail has-text-weight-semibold is-4">Kompetensi</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div class="column detail has-text-left">{{ aktKomponen.aktivitas.kompetensi }}</div>
      </div>
      <!-- Diagnosis -->
      <template v-if="komponenTitle === 'DIAGNOSIS'">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Diagnosis</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ aktKomponen.diagnosis.nama }}</div>
        </div>
      </template>
      <!-- Diagnosis -->

      <!-- Pemeriksaan -->
      <template v-else-if="komponenTitle === 'PEMERIKSAAN'">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Pemeriksaan</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ aktKomponen.pemeriksaan.nama }}</div>
        </div>
      </template>
      <!-- Pemeriksaan -->

      <!-- Tatalaksana -->
      <template v-else-if="komponenTitle === 'TATALAKSANA'">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Tatalaksana</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ aktKomponen.tatalaksana.nama }}</div>
        </div>
      </template>
      <!-- Tatalaksana -->

      <div class="columns is-mobile" v-if="komponenTitle !== 'KETERAMPILAN'">
        <div class="column detail has-text-weight-semibold is-4">Catatan</div>
        <div class="column detail has-text-weight-semibold is-1">:</div>
        <div class="column detail has-text-left">{{ aktKomponen.catatan }}</div>
      </div>

      <!-- Keterampilan -->
      <template v-if="komponenTitle === 'KETERAMPILAN'">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Keterampilan</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ aktKomponen.keterampilan.nama }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Catatan</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ aktKomponen.catatan }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Nilai Sikap</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="canVerifikasi">
              <b-field :type="{'is-danger': errorMap.nilai_sikap}" :message="errorMap.nilai_sikap">
                <b-numberinput
                  min="0"
                  max="100"
                  step="0.01"
                  @input="validateInput('nilai_sikap')"
                  v-model="aktKomponen.nilai_sikap"
                  :controls="false"
                ></b-numberinput>
              </b-field>
            </template>
            <template v-else>{{aktKomponen.nilai_sikap}}</template>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Verifikasi</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="canVerifikasi">
              <b-field :type="{'is-danger': errorMap.verifikasi}" :message="errorMap.verifikasi">
                <b-select
                  @input="validateInput('verifikasi')"
                  v-model="aktKomponen.verifikasi"
                  placeholder="fieldTitle"
                >
                  <option value="Belum">Ver: -</option>
                  <option value="Ya">Ver: ✓</option>
                  <option value="Tidak">Ver: ✗</option>
                </b-select>
              </b-field>
            </template>
            <hasil-mark v-else :hasil="aktKomponen.verifikasi"></hasil-mark>
          </div>
        </div>
        <div v-if="aktKomponen.waktu_verifikasi" class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Waktu Verifikasi</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ aktKomponen.waktu_verifikasi }}</div>
        </div>
        <div v-if="canVerifikasi" class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold"></div>
          <div class="column detail has-text-weight-semibold is-1"></div>
          <div class="column detail has-text-left">
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
        </div>
      </template>
      <div v-if="canUpload" class="columns is-mobile">
        <b-button loading v-if="isUploading">Loading</b-button>
        <b-field v-else class="file">
          <b-upload v-model="aktKomponen.foto" @input="resize" accept=".jpg, .jpeg, .png">
            <a class="button is-primary">
              <b-icon icon="upload"></b-icon>
              <span>Upload Foto</span>
            </a>
          </b-upload>
          <span class="file-name" v-if="aktKomponen.foto">{{ aktKomponen.foto.name }}</span>
        </b-field>
      </div>

      <ul v-if="hasFoto" class="box-gallery-container three-cols">
        <li
          v-for="foto in aktKomponen.foto_set"
          :key="foto.id"
          class="box-gallery show"
          style="transition-delay: 0s;"
        >
          <div class="inner">
            <a v-if="canDeleteFoto" @click="confirmDeleteFoto(foto)">
              <b-icon icon="times-circle" class="is-pulled-right"></b-icon>
            </a>
            <a :href="getWebpPath(foto.url)" class="glightbox">
              <img class="lampiran" :src="getWebpPath(foto.url)" />
            </a>
          </div>
        </li>
      </ul>
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
  </div>
</template>


<script>
import GLightbox from "glightbox";
import axios from "axios";
import { mapState } from "vuex";
import APP_CONFIG from "@/apps/core/modules/config.js";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import AktKomponen from "../models/aktKomponenDetail.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";
import { webpPath } from "@/apps/core/modules/utils.js";

const CHANGE_URL_MAP = {
  PEMERIKSAAN: "aktivitas-pemeriksaan-change",
  TATALAKSANA: "aktivitas-tatalaksana-change",
  DIAGNOSIS: "aktivitas-diagnosis-change",
  KETERAMPILAN: "aktivitas-keterampilan-change",
};

export default {
  name: "AktivitasKomponenDetail",
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  props: { title: String, komponenTitle: String },
  components: {
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  data() {
    this.aktKompMdl = new AktKomponen();
    this.objectMdl = this.aktKompMdl;
    let urlName = this.komponenTitle.toLowerCase().replace(" ", "");
    this.aktKompMdl.setApiURL(`${APP_CONFIG.baseAPIURL}/aktivitas/${urlName}/`);
    let obv = this.aktKompMdl.getObservables();
    obv.changeUrl = CHANGE_URL_MAP[this.komponenTitle];
    const dirName = `aktivitas${this.komponenTitle.toLowerCase()}`;
    this.uploadPhoto = new UploadPhoto(dirName, this.notify);
    obv.isUploading = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    isValid() {
      return this.validity.edited && this.validity.validated;
    },
    canVerifikasi() {
      return this.isLoaded && this.aktKomponen.extra_data.can_verifikasi;
    },
    canChangeKomponen() {
      return this.isLoaded && this.aktKomponen.extra_data.can_change;
    },
    canDeleteKomponen() {
      return this.isLoaded && this.aktKomponen.extra_data.can_delete;
    },
    canUpload() {
      return this.isLoaded && this.aktKomponen.extra_data.can_add_foto;
    },
    canDeleteFoto() {
      return this.isLoaded && this.aktKomponen.extra_data.can_add_foto;
    },
    aktivitasDetailURL() {
      if (this.aktKomponen.aktivitas.id) {
        return this.$router.resolve({
          name: "aktivitas-detail",
          params: { id: this.aktKomponen.aktivitas.id },
        }).href;
      }
      return "";
    },
    changeRouterTo() {
      if (this.aktKomponen.aktivitas.jenis_keterampilan != "Virtual")
        return {
          name: this.changeUrl,
          params: { id: this.aktKomponen.id },
        };
      return {
        name: "keterampilan-virtual-change",
      };
    },
    perawatanDetailURL() {
      if (this.aktKomponen.perawatan.id) {
        return this.$router.resolve({
          name: "klinis-detail",
          params: { id: this.aktKomponen.perawatan.id },
        }).href;
      }
      return "";
    },
    hasFoto() {
      return this.aktKomponen.foto_set.length > 0;
    },
  },
  methods: {
    getWebpPath(url) {
      return webpPath(url);
    },
    notify(uploading) {
      this.isUploading = uploading;
    },
    fetchData() {
      this.aktKompMdl.load(this.$route.params.id, this.onLoaded);
    },
    onSaved(respData) {
      this.aktKomponen.waktu_verifikasi = respData.waktu_verifikasi;
    },
    saveObject() {
      this.aktKompMdl.update(this.onSaved);
    },
    validateInput(field) {
      this.aktKompMdl.validate(field);
    },
    onLoaded() {
      if (!this.canUpload) return;
      this.uploadPhoto.requestAWS();
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.uploadPhoto.upload(foto, () => this.saveFoto(url), null, () => {
          this.onLoaded();
        });
      });
    },
    saveFoto(url) {
      const uploadDocUrl = `${this.aktKompMdl.getApiURL()}${this.aktKomponen.id}/foto/`;
      const data = { url: url };
      axios
        .post(uploadDocUrl, data)
        .then((response) => {
          this.aktKomponen.foto_set.splice(0, 0, {
            id: response.data.id,
            url: response.data.url,
          });
          this.aktKomponen.foto = null;
          this.$buefy.toast.open("Foto berhasil di upload.");
        })
        .catch(() => {
          this.$buefy.toast.open("Foto gagal di upload.");
        });
    },
    confirmDeleteFoto(foto) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> foto ini?",
        confirmText: "Hapus Foto",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          const params = {akt_pk: this.aktKomponen.id, model_name: `Aktivitas${this.title}`};
          const url = `foto/${foto.id}`;
          this.aktKompMdl.delete(url, () => {
            const idx = this.aktKomponen.foto_set.indexOf(foto);
            this.aktKomponen.foto_set.splice(idx, 1);
          }, params);
        },
      });
    },
  },
  mounted() {
    this.glightbox = GLightbox();
  },
  updated() {
    this.glightbox.reload();
  },
  watch: {
    aktKomponen: {
      // aktivitas berubah, update edited
      handler(newValue, oldValue) {
        if (!this.aktKompMdl.getEdited()) {
          this.aktKompMdl.setEdited(!!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../core/assets/css/glightbox.css";
</style>
